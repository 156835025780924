import React, { useEffect, useState } from 'react';
import './header.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

export default function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation('header');
    const { lang } = useParams();

    // Determine if we're in the trainerfinder subpath
    const isTrainerfinder = location.pathname.includes('/trainerfinder');
    const [language, setLanguage] = useState(lang);

    // Main Navigation Items
    const jobboerseNavItems = [
        { title: t("Swiss Cycling Jobbörse"), link: `/${lang}` },
        { title: t("Neuer Eintrag"), link: `/${lang}/neuer-eintrag` },
        { title: t("Trainer Finder"), link: `https://swiss-cycling-trainerfinder.ch/${language}`, target: "__blank" },

    ];

    const trainerfinderNavItems = [
        { title: t("Swiss Cycling Trainerfinder"), link: `/trainerfinder/${lang}` },
        { title: t("Neuer Eintrag"), link: `/trainerfinder/${lang}/neuer-eintrag` },
    ];

    // Choose the appropriate navigation items based on the context
    const mainNavItems = isTrainerfinder ? trainerfinderNavItems : jobboerseNavItems;

    function getUUID() {
        const urlParams = new URLSearchParams(window.location.search);
        let uuid = urlParams.get('uuid');

        if (!uuid) {
            uuid = localStorage.getItem('uuid');
        } else {
            localStorage.setItem('uuid', uuid);
        }

        return uuid;
    }

    const uuid = getUUID();

    // Meta Navigation Items
    const metaNavItems = [
        { title: t("Über Swiss Cycling"), link: "https://swiss-cycling.ch", target: "__blank" },
    ];

    if (uuid) {
        mainNavItems.push({ title: t("Eintrag bearbeiten"), link: `${isTrainerfinder ? `/trainerfinder/${lang}` : `/${lang}`}/eintrag-bearbeiten` });
        metaNavItems.push({ title: t("Logout"), link: `${isTrainerfinder ? `/trainerfinder/${lang}` : `/${lang}`}/login` });
    } else {
        metaNavItems.push({ title: t("Login"), link: `${isTrainerfinder ? `/trainerfinder/${lang}` : `/${lang}`}/login` });
    }

    const languageItems = [
        { title: "DE", language: "de" },
        { title: "FR", language: "fr" },
    ];

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);

        const newPath = location.pathname.replace(/^\/(?:trainerfinder\/)?[a-z]{2}(\/|$)/, `/${isTrainerfinder ? 'trainerfinder/' : ''}${language}$1`);
        navigate(newPath, { replace: true });
    };

    useEffect(() => {
        changeLanguage(lang);
    }, [lang]);

    return (
        <header className="header">
            <div className="container">
                <div className="header--left">
                    <div className="meta-nav-wrapper">
                        <ul className="nav meta-nav">
                            {metaNavItems.map((item, index) => (
                                <li key={index} className="item">
                                    <Link to={item.link} target={item.target}>{item.title}</Link>
                                </li>
                            ))}
                            {languageItems.map((item, index) => (
                                <li key={index} className="item language">
                                    <span onClick={() => changeLanguage(item.language)}>{item.title}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="main-nav-wrapper">
                        <ul className="nav main-nav">
                            {mainNavItems.map((item, index) => (
                                <li key={index} className="item">
                                    <Link to={item.link} target={item.target}>{item.title}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="header--right">
                    <a href="https://swiss-cycling.ch" target="__blank">
                        <img src={process.env.PUBLIC_URL + "/assets/sc_logo.png"} alt="swiss cycling ausbildung logo" />
                    </a>
                </div>
            </div>
        </header>
    );
}
